/* You can add global styles to this file, and also import other style files */
@import "src/assets/css/metro-all.css?ver=@@b-version";
@import "src/assets/css/dialogs.css";
@import "src/assets/css/schemes/sky-net.css";
@import "../node_modules/@boldreports/javascript-reporting-controls/Content/material/bold.reports.all.min.css";

.isGreen {
  color: #12a19a;
}

.h-menu > li.active,
ul > li > a.active {
  background-color: #005696;
  color: white;
}
